<template>
  <div class="TaskTotal">
    <span class="label">{{ label }}:</span>
    <span class="total">{{ total }}</span>
  </div>
</template>

<script>
export default {
  props: {
    total: {
      type: String,
      required: true,
      default: () => '',
    },
    label: {
      type: String,
      required: false,
      default: 'Today',
    },
  },
};
</script>

<style lang="stylus"
       scoped>
  .TaskTotal
    display grid
    grid-template-columns repeat(2, 1fr)
    grid-gap 5px
    text-align center
    align-content center
    color #f56b6c

  .label
    font-weight bold
</style>
